<template>
  <div class="box box_mm">
    <div class="box_main">
      <div class="search_box">
        <div class="search_item">
          <span style="width: 0.6rem">名称：</span>
          <el-input
            v-model="searchinput"
            placeholder="请输入名称"
            style="width: 2rem"
          ></el-input>
        </div>
        <div class="search_item">
          <span style="width: 0.6rem">年份：</span>
          <el-select
            v-model="searchnian"
            placeholder="请选择"
            filterable
            clearable
            style="width: 2rem"
            @change="search"
          >
            <el-option label="2024委员履职表" value="2024"> </el-option>
            <el-option label="2023委员履职表" value="2023"> </el-option>
            <el-option label="全部" value=""> </el-option>
          </el-select>
        </div>
        <div class="search_item">
          <el-button type="primary" @click="search">搜索</el-button>
        </div>
      </div>
      <div class="box_cont">
        <div class="btn_list"></div>
        <div style="height: 76vh; box-sizing: border-box">
          <MyTable
            ref="zjlist"
            :tableData="tableData"
            :tableHeader="tableHeader"
            :showbm="true"
            :czwidth="220"
            @getdata="gettable"
            @changswitch="changswitch"
          >
            <template slot="btns" slot-scope="{ scope }">
              <el-button @click="showeidt(scope.row)" type="success" size="mini"
                >详情</el-button
              >
              <el-button @click="pass(scope.row)" type="primary" size="mini"
                >通过</el-button
              >
              <!-- <el-button @click="bohui(scope.row)" type="danger" size="mini">驳回</el-button> -->
            </template>
          </MyTable>
        </div>
      </div>
    </div>

    <detail ref="mydetail"></detail>
  </div>
</template>

<script>
import detail from "./component/detail.vue";
export default {
  components: {
    detail,
  },
  data() {
    return {
      searchinput: "",
	  searchnian:'2024',
      tableHeader: [
        {
          prop: "id",
          name: "序",
          width: "50",
        },
        {
          prop: "realname",
          name: "用户名",
          width: "",
        },
        {
          prop: "mobile",
          name: "手机号",
          width: "",
        },
        {
          prop: "gender",
          name: "性别",
          width: "",
        },
        {
          prop: "apply_status",
          name: "状态",
          width: "",
          type: "status_lztb",
        },
        {
          prop: "createtime",
          name: "提交时间",
          width: "",
        },
      ],
      tableData: [],
      showcode: false,
      qr_url: "",
      imageLogo: require("@/assets/logo.png"),
    };
  },
  mounted() {
    this.gettable();
  },
  methods: {
    editerow(row) {
      this.$router.push({
        path: "/system/activity_edit",
        query: {
          id: row.id,
        },
      });
    },
    pass(row) {
      this.$confirm("请确认是否审核通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post({
            url: "/api/user_performance_form/performance_form_apply_pass",
            params: {
              performance_form_id: row.id,
            },
          }).then((res) => {
            this.$message.success("操作成功");
            this.gettable();
          });
        })
        .catch(() => {});
    },
    bohui(row) {
      this.$prompt("请输入驳回原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请输入驳回理由",
      })
        .then(({ value }) => {
          this.$post({
            url: "/api/user_performance_form/performance_form_apply_refund",
            params: {
              performance_form_id: row.id,
              refund_text: value,
            },
          }).then((res) => {
            this.$message.success("操作成功");
            this.gettable();
          });
        })
        .catch(() => {});
    },
    showeidt(row) {
      this.$refs.mydetail.dialogVisible = true;
      row.diaoyan = JSON.parse(row.diaoyan);
      row.form_summarize = JSON.parse(row.form_summarize);
      row.honor_punish_content = JSON.parse(row.honor_punish_content);
      row.meeting_speak = JSON.parse(row.meeting_speak);
      row.other = JSON.parse(row.other);
      row.shicha = JSON.parse(row.shicha);
      this.$refs.mydetail.form = row;
    },
    changswitch(row) {
      this.$post({
        url: "/api/room/edit",
        params: row,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.gettable();
      });
    },
    search() {
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    gettable() {
      this.$post({
        url: "/api/user_performance_form/performance_from_list",
        params: {
          apply_status: "0",
          is_review: "1",
          name: this.searchinput,
          nian: this.searchnian,
          p: this.$refs.zjlist.inputpage,
          size: this.$refs.zjlist.pagesize,
        },
      }).then((res) => {
        this.tableData = res.list;
        this.$refs.zjlist.total = res.count;
      });
    },
    addnew() {
      this.$router.push("/system/activity_add");
    },
    showdate(row) {
      this.$refs.myroomdate.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "index.scss";
</style>
